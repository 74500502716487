exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-collective-wisdom-js": () => import("./../../../src/pages/collective-wisdom.js" /* webpackChunkName: "component---src-pages-collective-wisdom-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-credentials-js": () => import("./../../../src/pages/credentials.js" /* webpackChunkName: "component---src-pages-credentials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recommendations-js": () => import("./../../../src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-services-development-programs-js": () => import("./../../../src/pages/services/development-programs.js" /* webpackChunkName: "component---src-pages-services-development-programs-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

